/* eslint-disable camelcase */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { BodyBold } from 'ggx-componentlibrary/design/typography/typography.styles'
import { translate } from 'ggx-service/i18n/translate.service'
import { formatCurrency } from 'ggx-service/i18n/format-currency.service'
import {
  Container,
  ItemWrapper,
  BreakdownItem,
  FeeRemark,
  BreakdownItemBold,
  BreakdownItemWithIcon,
  StyledTooltipLink,
  StyledTooltipIcon,
  Hr,
} from './price-breakdown.component.styles'
import { getCountry } from 'ggx-service/locale/country.service'
import { getLocale } from 'ggx-src/legacy/utils/locale'

import { getCargoCompensationItemPostfix } from 'ggx-src/van-booking/order/sidebar/order-form/summary/order-breakdown/price-breakdown/price-breakdown.component'
import { useFeatureFlagValue } from 'ggx-global/hooks/use-feature-flag'

const Breakdown = ({ title, value }) => (
  <ItemWrapper noMargin>
    <BreakdownItem typographyStyles="c-black">{title}</BreakdownItem>
    <BreakdownItem>{value}</BreakdownItem>
  </ItemWrapper>
)

Breakdown.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
}

const standardAdditionalServiceKeys = [
  'compartment_vehicle',
  'borrow_carts',
  'borrow_forklift_pcs',
  'remove_net',
  'pet',
  'need_pet',
  'new_car',
  'speak_english',
  'carry',
  'free_trade_zone_delivery',
  'front_seat_passenger',
  'dumper_construction_waste',
  'return_trip',
  'door_to_door_delivery',
  'proxy_payment',
  'movers',
  'moving_ground_floor',
  'moving_up_or_down_stairs',
  'moving_up_and_down_stairs',
  'assistants',
  'night_charge',
  'need_covered_truck',
  'need_tailgate',
  'need_delivery_box',
  'need_refrigeration',
  'need_carry',
  'night_extra_charge',
  'midnight_extra_charge',
  'holiday_extra_charge',
  'same_region_charge',
  'bonus',
  'premium_service',
  'voucher_code_discount',
  'voucher_code',
  'cargo_insurance_level',
  'multi_point_concession',
  'need_premium_service',
  'moving_ground_floor_both_ways',
]

const lowerCaseObjectKey = object =>
  Object.keys(object).reduce((accumulator, key) => {
    // eslint-disable-next-line no-param-reassign
    accumulator[key.toLowerCase()] = object[key]
    return accumulator
  }, {})

const PriceBreakdown = ({ order, breakdownResponse = {} }) => {
  const [t] = useTranslation()
  const passengerCount = order?.order_request_detail?.passenger_count
  const cartCount = order?.order_request_detail?.cart_count
  const vehicleType = order?.vehicle
  const orderIsCompleted = order?.status === 'completed'

  const parseBreakdownResponse = useCallback(breakdownArg => {
    if (breakdownArg.is_new_pricing_breakdown) {
      const { breakdown } = breakdownArg
      const breakdownWithAdditionalRequirementAndFee = breakdown
        .filter(({ key }) => key !== 'total')
        .reduce((breakdownObject, { key, amount, labels, quantity }) => {
          let title = t(key)
          const currentLocale = getLocale()

          if (labels) {
            const labelsWithLowercaseKey = lowerCaseObjectKey(labels)

            title = labelsWithLowercaseKey[currentLocale]
          }

          /* eslint-disable-next-line no-param-reassign */
          breakdownObject[key] = {
            title,
            value: amount,
            quantity,
          }
          return breakdownObject
        }, {})

      const quotationNew = {
        breakdown: breakdownWithAdditionalRequirementAndFee,
        total: parseInt(
          breakdown.find(item => item.key === 'total').amount,
          10
        ),
      }

      return quotationNew
    }

    return breakdownArg
  }, [])

  const formattedBreakdown = parseBreakdownResponse(breakdownResponse)
  const { breakdown = {}, total } = formattedBreakdown
  const { fee, ...additionalService } = breakdown

  const displayedTotal =
    additionalService.carry || additionalService.dumper_construction_waste
      ? `${translate(
          'newOrder.tab.summary.title.estimatedTotal.price.quote'
        )} + ${formatCurrency({ amount: total })}`
      : formatCurrency({ amount: total })

  const displayedRouteFee = formatCurrency({ amount: fee?.value })
  const customAdditionalServiceKeys = Object.keys(additionalService).filter(
    key => !standardAdditionalServiceKeys.includes(key)
  )

  const moveGoodsSummaryTitle =
    getCountry() === 'TW' && vehicleType === 'van'
      ? t('newOrder.tab.additional.selection.doorToDoorDelivery.title')
      : translate('newOrder.tab.summary.breakdown.moveGoods')

  const getMoveGoodsSummaryValue = value =>
    getCountry() === 'TW'
      ? formatCurrency({ amount: value })
      : translate('newOrder.tab.summary.title.estimatedTotal.price.quote')

  const PriceTotalLabel = orderIsCompleted
    ? t('common__total')
    : t('newOrder.tab.summary.title.estimatedTotal.label')

  const feeBreakdownExplanation =
    getCountry() === 'HK'
      ? t('estimated_total__details__tunnel')
      : translate('newOrder.tab.summary.feeRemark')

  return formattedBreakdown.total === undefined ? (
    <Container>
      <BodyBold noMargin>{t('common__price_breakdown')}</BodyBold>
    </Container>
  ) : (
    <Container>
      <BodyBold noMargin>{t('common__price_breakdown')}</BodyBold>

      <Breakdown
        title={t('common__price_breakdown__base_fee')}
        value={displayedRouteFee}
      />

      {additionalService.voucher_code_discount && (
        <Breakdown
          title={t('coupon')}
          value={formatCurrency({
            amount: additionalService.voucher_code_discount.value,
          })}
        />
      )}
      {additionalService.voucher_code && (
        <Breakdown
          title={t('coupon')}
          value={formatCurrency({
            amount: additionalService.voucher_code.value,
          })}
        />
      )}

      {passengerCount ? (
        <Breakdown
          title={translate('newOrder.tab.summary.breakdown.passengers').replace(
            '%',
            passengerCount
          )}
          value={formatCurrency({
            amount: additionalService.compartment_vehicle
              ? additionalService.compartment_vehicle.value
              : 0,
          })}
        />
      ) : null}

      {additionalService.cargo_insurance_level && (
        <ItemWrapper noMargin>
          <BreakdownItemWithIcon typographyStyles="c-black">
            {t('orderDetails.insurance.insurance')}
            {getCargoCompensationItemPostfix(
              t,
              additionalService.cargo_insurance_level.title
            )}
            <StyledTooltipLink
              href="https://www.gogox.com/compensation/"
              target="_blank"
            >
              <StyledTooltipIcon />
            </StyledTooltipLink>
          </BreakdownItemWithIcon>
          <BreakdownItem>
            {formatCurrency({
              amount: additionalService.cargo_insurance_level?.value ?? 0,
            })}
          </BreakdownItem>
        </ItemWrapper>
      )}

      {additionalService.borrow_carts && (
        <Breakdown
          title={translate('newOrder.tab.summary.breakdown.rentCart').replace(
            '%',
            () => {
              if (cartCount) {
                return cartCount
              }

              if (additionalService.borrow_carts.quantity) {
                return additionalService.borrow_carts.quantity
              }

              return '1'
            }
          )}
          value={formatCurrency({
            amount: additionalService.borrow_carts.value ?? 0,
          })}
        />
      )}

      {cartCount > 0 && additionalService.borrow_forklift_pcs && (
        <Breakdown
          title={translate(
            'newOrder.tab.summary.breakdown.rentForklift'
          ).replace('%', cartCount)}
          value={formatCurrency({
            amount: additionalService.borrow_forklift_pcs.value,
          })}
        />
      )}

      {additionalService.remove_net && (
        <Breakdown
          title={t('newOrder.tab.additional.selection.longGoods.summary')}
          value={formatCurrency({ amount: 20 })}
        />
      )}

      {additionalService?.remove_net?.value === 40 && (
        <Breakdown
          title={t('newOrder.tab.additional.selection.tallGoods.summary')}
          value={formatCurrency({ amount: 20 })}
        />
      )}

      {additionalService.pet && (
        <Breakdown
          title={translate('newOrder.tab.summary.breakdown.pet')}
          value={formatCurrency({ amount: additionalService.pet.value })}
        />
      )}

      {additionalService.need_pet && (
        <Breakdown
          title={t('order_request__additional_service__pets')}
          value={formatCurrency({ amount: additionalService.need_pet.value })}
        />
      )}

      {additionalService.new_car && (
        <Breakdown
          title={
            additionalService.new_car.value === 5 || getCountry() !== 'HK'
              ? translate('newOrder.tab.summary.breakdown.newVehicle')
              : t('ar_premium_van')
          }
          value={formatCurrency({ amount: additionalService.new_car.value })}
        />
      )}

      {additionalService.speak_english && (
        <Breakdown
          title={translate('newOrder.tab.summary.breakdown.engDriver')}
          value={formatCurrency({
            amount: additionalService.speak_english.value,
          })}
        />
      )}

      {additionalService.premium_service && (
        <ItemWrapper noMargin>
          <BreakdownItemWithIcon typographyStyles="c-black">
            {additionalService.premium_service.title}
            <StyledTooltipLink
              target="_blank"
              href={t('url__platform_support')}
            >
              <StyledTooltipIcon />
            </StyledTooltipLink>
          </BreakdownItemWithIcon>
          <BreakdownItem>
            {formatCurrency({
              amount: additionalService.premium_service.value,
            })}
          </BreakdownItem>
        </ItemWrapper>
      )}

      {additionalService.need_premium_service &&
        additionalService.need_premium_service.value > 0 && (
          <ItemWrapper noMargin>
            <BreakdownItemWithIcon typographyStyles="c-black">
              {t('need_premium_service')}
              <StyledTooltipLink
                target="_blank"
                href={t('url__platform_support')}
              >
                <StyledTooltipIcon />
              </StyledTooltipLink>
            </BreakdownItemWithIcon>
            <BreakdownItem>
              {formatCurrency({
                amount: additionalService.need_premium_service.value,
              })}
            </BreakdownItem>
          </ItemWrapper>
        )}

      {additionalService.carry && (
        <Breakdown
          title={moveGoodsSummaryTitle}
          value={getMoveGoodsSummaryValue(additionalService.carry.value)}
        />
      )}

      {additionalService.free_trade_zone_delivery && (
        <Breakdown
          title={t('order_request__additional_service__restricted_area')}
          value={formatCurrency({
            amount: additionalService.free_trade_zone_delivery.value,
          })}
        />
      )}

      {additionalService.front_seat_passenger && (
        <Breakdown
          title={t('order_request__additional_service__front_seat_passenger')}
          value={formatCurrency({
            amount: additionalService.front_seat_passenger.value,
          })}
        />
      )}

      {additionalService.dumper_construction_waste && (
        <Breakdown
          title={translate('newOrder.tab.summary.breakdown.disposeWaste')}
          value={translate(
            'newOrder.tab.summary.title.estimatedTotal.price.quote'
          )}
        />
      )}

      {additionalService.return_trip && (
        <Breakdown
          title={t('newOrder.tab.additional.selection.returnTrip.title')}
          value={formatCurrency({
            amount: additionalService.return_trip.value,
          })}
        />
      )}

      {additionalService.door_to_door_delivery && (
        <Breakdown
          title={t(
            'newOrder.tab.additional.selection.doorToDoorDelivery.title'
          )}
          value={formatCurrency({
            amount: additionalService.door_to_door_delivery.value,
          })}
        />
      )}

      {additionalService.proxy_payment && (
        <Breakdown
          title={t('newOrder.tab.additional.selection.proxyBuying.title')}
          value={formatCurrency({
            amount: additionalService.proxy_payment.value,
          })}
        />
      )}

      {additionalService.movers && (
        <Breakdown
          title={t('order_request__additional_service__movers')}
          value={formatCurrency({ amount: additionalService.movers.value })}
        />
      )}

      {additionalService.moving_ground_floor && (
        <Breakdown
          title={t(
            'newOrder.tab.additional.selection.movingHelp.options.groundFloor'
          )}
          value={formatCurrency({
            amount: additionalService.moving_ground_floor.value,
          })}
        />
      )}

      {additionalService.moving_ground_floor_both_ways && (
        <Breakdown
          title={t('common__both_way_ground_floor')}
          value={formatCurrency({
            amount: additionalService.moving_ground_floor_both_ways.value,
          })}
        />
      )}

      {additionalService.moving_up_or_down_stairs && (
        <Breakdown
          title={t(
            'newOrder.tab.additional.selection.movingHelp.options.upstairsOrDownstairs'
          )}
          value={formatCurrency({
            amount: additionalService.moving_up_or_down_stairs.value,
          })}
        />
      )}

      {additionalService.moving_up_and_down_stairs && (
        <Breakdown
          title={t(
            'newOrder.tab.additional.selection.movingHelp.options.upstairsAndDownstairs'
          )}
          value={formatCurrency({
            amount: additionalService.moving_up_and_down_stairs.value,
          })}
        />
      )}

      {additionalService.assistants && (
        <Breakdown
          title={t(
            'newOrder.tab.additional.selection.movingHelp.options.extra'
          )}
          value={formatCurrency({ amount: additionalService.assistants.value })}
        />
      )}

      {additionalService.night_charge && (
        <Breakdown
          title={t('newOrder.tab.summary.breakdown.nightcharge')}
          value={formatCurrency({
            amount: additionalService.night_charge.value,
          })}
        />
      )}

      {additionalService.need_covered_truck && (
        <Breakdown
          title={t('order_request__additional_service__covered_lorry')}
          value={formatCurrency({
            amount: additionalService.need_covered_truck.value ?? 0,
          })}
        />
      )}

      {additionalService.need_tailgate && (
        <Breakdown
          title={t('order_request__additional_service__tailgate')}
          value={formatCurrency({
            amount: additionalService.need_tailgate.value ?? 0,
          })}
        />
      )}

      {additionalService.need_delivery_box && (
        <Breakdown
          title={t('order_request__additional_service__delivery_box')}
          value={formatCurrency({
            amount: additionalService.need_delivery_box.value ?? 0,
          })}
        />
      )}

      {additionalService.need_refrigeration && (
        <Breakdown
          title={t('order_request__additional_service__refrigeration')}
          value={formatCurrency({
            amount: additionalService.need_refrigeration.value,
          })}
        />
      )}

      {additionalService.need_carry && (
        <Breakdown
          title={t('order_request__additional_service__need_carry')}
          value={formatCurrency({
            amount: additionalService.need_carry.value ?? 0,
          })}
        />
      )}

      {additionalService.night_extra_charge && (
        <Breakdown
          title={t('order_request__additional_service__night_extra_charge')}
          value={formatCurrency({
            amount: additionalService.night_extra_charge.value,
          })}
        />
      )}

      {additionalService.midnight_extra_charge && (
        <Breakdown
          title={t('order_request__additional_service__midnight_extra_charge')}
          value={formatCurrency({
            amount: additionalService.midnight_extra_charge.value,
          })}
        />
      )}

      {additionalService.holiday_extra_charge && (
        <Breakdown
          title={t('order_request__additional_service__holiday_extra_charge')}
          value={formatCurrency({
            amount: additionalService.holiday_extra_charge.value,
          })}
        />
      )}

      {additionalService.same_region_charge && (
        <Breakdown
          title={t('order_request__additional_service__same_region_charge')}
          value={formatCurrency({
            amount: additionalService.same_region_charge.value,
          })}
        />
      )}

      {additionalService.multi_point_concession ? (
        <Breakdown
          title={t('multi_point_concession')}
          value={formatCurrency({
            amount: additionalService.multi_point_concession.value,
          })}
        />
      ) : null}

      {additionalService.bonus && (
        <Breakdown
          title={t('common__driver_bonus')}
          value={formatCurrency({ amount: additionalService.bonus.value })}
        />
      )}

      {customAdditionalServiceKeys.map(serviceKey => {
        const lang = getLocale().replace(
          /-(\w{2})$/,
          (_, p1) => '_' + p1.toUpperCase()
        )
        return (
          <Breakdown
            key={serviceKey}
            title={
              additionalService[serviceKey].extras?.[lang] ||
              additionalService[serviceKey].title
            }
            value={formatCurrency({
              amount: additionalService[serviceKey].value,
            })}
          />
        )
      })}
      <Hr />
      <div>
        <ItemWrapper style={{ marginBottom: '4px' }}>
          <BreakdownItemBold>{PriceTotalLabel}</BreakdownItemBold>
          <BodyBold noMargin>{displayedTotal}</BodyBold>
        </ItemWrapper>
        <FeeRemark noMargin>{feeBreakdownExplanation}</FeeRemark>
      </div>
    </Container>
  )
}

PriceBreakdown.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
  }),
  breakdownResponse: PropTypes.object,
}

export { PriceBreakdown, Breakdown }
