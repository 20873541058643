// @flow

import { Api } from '../../legacy/utils/api'
import MapUtils from '../../legacy/utils/map'

const BASE_URL = (
  localStorage.getItem('api_url') || Api.BASE_URL.API_URL
).replace('w1', 'v1')
const _placesApi = new Api(BASE_URL)

const placesService = {
  placesAutocomplete: async (placeName: string) => {
    const method = 'get'
    const endpoint = '/places/autocomplete'
    const params = { input: placeName }

    try {
      const result = await _placesApi.request(
        method,
        endpoint,
        null,
        null,
        null,
        null,
        params
      )
      if (result.data && result.data.predictions) {
        return result.data.predictions
      }
      return []
    } catch (error) {
      throw error
    }
  },

  placesDetails: async (placeid: number) => {
    const method = 'get'
    const endpoint = '/places/details'
    const params = { placeid }

    try {
      const result = await _placesApi.request(
        method,
        endpoint,
        null,
        null,
        null,
        null,
        params
      )
      return result.data.result
    } catch (error) {
      throw error
    }
  },

  getPlaceFromLatitudeLongitude: async ({ lat, lng }) => {
    const geocoder = new google.maps.Geocoder()
    try {
      // an endpoint will be created for below in BET-255
      const places = await MapUtils.reverseGeocode(geocoder, { lat, lng })
      return {
        address: places[0].formatted_address,
        lat,
        lon: lng,
      }
    } catch (error) {
      throw error
    }
  },
}

export { placesService, _placesApi }
