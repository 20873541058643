import styled from "styled-components"

import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import { COLOR } from "ggx-componentlibrary/design/color/color.constants"
import {
  SmallText,
  BodySemiBold,
} from "ggx-componentlibrary/design/typography/typography.styles"
import TooltipIcon from 'ggx-componentlibrary/components/tooltip/assets/info.svg'

const Container = styled.div`
  margin-bottom: ${SPACING.L};
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${SPACING.XS};
  ${(props) => props.noMargin && "margin: 0;"}
`

const FeeRemark = styled(SmallText)``

const BreakdownItem = styled(SmallText)`
  margin: 0;
`

const BreakdownItemBold = styled(BodySemiBold)`
  margin: 0;
`

const BreakdownItemWithIcon = styled(SmallText)`
  margin: 0;
  display: flex;
`

const StyledTooltipLink = styled.a`
  display: inline-flex;
  margin-left: 4px;
`

const StyledTooltipIcon = styled(TooltipIcon)`
  display: inline-block;
  height: 16px;
  width: 16px;
  cursor: pointer;
  transition: filter 300ms eas-in;
  &:hover {
    filter: brightness(1.2);
  }
`

const Hr = styled.div`
  border-bottom: 1px solid ${COLOR.GREY_LIGHT};
  margin: 0;
`

export {
  Container,
  ItemWrapper,
  FeeRemark,
  BreakdownItem,
  BreakdownItemBold,
  BreakdownItemWithIcon,
  StyledTooltipLink,
  StyledTooltipIcon,
  Hr
}
