import { useEffect, useRef } from 'react'
import setWith from 'lodash/setWith'
import clone from 'lodash/clone'

const mutateByPath = (source, path, value) => {
  return setWith(clone(source), path, value, clone)
}

export const useConvertToReduxFormProp = (
  name,
  {
    register,
    watch,
    setValue,
    formState: { dirtyFields, touchedFields, errors, defaultValues },
  },
  options = {}
) => {
  const inputProps = register(name, options)
  const dirty = dirtyFields[name]
  const touched = touchedFields[name]
  const error = errors[name]
  const propsRef = useRef({
    input: {
      name,
      onBlur: value => {
        setValue(name, value, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        })
      },
      onChange: value => {
        setValue(name, value, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        })
      },
      value: defaultValues?.[name] || '',
    },
    meta: {
      dirty,
      touched,
      error: error?.message,
    },
  })
  useEffect(() => {
    const subscription = watch((value, { name: _name }) => {
      if (name === _name) {
        propsRef.current = mutateByPath(
          propsRef.current,
          'input.value',
          value[name] ?? ''
        )
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])
  if (propsRef.current.meta.dirty !== dirty) {
    propsRef.current = mutateByPath(propsRef.current, 'meta.dirty', dirty)
  }
  if (propsRef.current.meta.touched !== touched) {
    propsRef.current = mutateByPath(propsRef.current, 'meta.touched', touched)
  }
  if (propsRef.current.meta.error !== error?.message) {
    propsRef.current = mutateByPath(
      propsRef.current,
      'meta.error',
      error?.message
    )
  }
  return [propsRef.current, inputProps]
}
