import { Api } from 'ggx-src/legacy/utils/api'
import { getUserId } from 'ggx-src/legacy/views/helpers'
import { getAuthToken } from 'ggx-src/legacy/views/helpers'

const _addressBookApi = new Api(Api.BASE_URL.API_URL)
let lastAuthorization = undefined
let lastList = undefined
let lastRecentUsedAddresses = undefined

const addressBookService = {
  getList: async () => {
    const authorization = getAuthToken()
    if (lastAuthorization === authorization && lastList) return lastList

    lastAuthorization = authorization
    const method = 'get'
    const endpoint = `/b2b/address_books/list`

    try {
      const result = await _addressBookApi.request(method, endpoint)
      return (lastList = result.data)
    } catch (error) {
      throw error
    }
  },
  addAddress: async (address = {}) => {
    const method = 'post'
    const endpoint = `/b2b/address_books`

    try {
      const result = await _addressBookApi.request(method, endpoint, null, {
        address: {
          user_id: Number(getUserId()),
          nickname: address.nickname,
          contact_name: address.contact_name,
          phone_number: address.contact_phone_number,
          street_address: address.address,
          address_detail: address.detail_address,
          email: address.email,
          lat: address.lat,
          lon: address.lon,
        },
      })
      lastList = undefined
      return result.data
    } catch (error) {
      throw error
    }
  },
  editAddress: async (address = {}) => {
    const method = 'put'
    const endpoint = `/b2b/address_books`

    try {
      const result = await _addressBookApi.request(method, endpoint, null, {
        id: address.id,
        address: {
          id: address.id,
          user_id: Number(getUserId()),
          nickname: address.nickname,
          contact_name: address.contact_name,
          phone_number: address.contact_phone_number,
          street_address: address.address,
          address_detail: address.detail_address,
          email: address.email,
          lat: address.lat,
          lon: address.lon,
        },
      })
      lastList = undefined
      return result.data
    } catch (error) {
      throw error
    }
  },
  bulkDelete: async (ids = []) => {
    const method = 'delete'
    const endpoint = `/b2b/address_books/bulk_delete`

    try {
      const result = await _addressBookApi.request(
        method,
        endpoint,
        null,
        null,
        {},
        {},
        { ids }
      )
      lastList = undefined
      return result.data
    } catch (error) {
      throw error
    }
  },
  getRecentUsedAddresses: async () => {
    const authorization = _addressBookApi.defaultHeader.Authorization
    if (lastAuthorization === authorization && lastRecentUsedAddresses)
      return lastRecentUsedAddresses

    lastAuthorization = authorization
    const method = 'get'
    const endpoint = `/b2b/address_books/recent_used_addresses`

    try {
      const result = await _addressBookApi.request(method, endpoint)
      return (lastRecentUsedAddresses = result.data)
    } catch (error) {
      throw error
    }
  },
  updateLastUsedAddress: async id => {
    const method = 'put'
    const endpoint = `/b2b/address_books/update_last_used_address`

    try {
      const result = await _addressBookApi.request(method, endpoint, null, {
        address: { id },
      })
      lastList = undefined
      return result.data
    } catch (error) {
      throw error
    }
  },
}

export { _addressBookApi, addressBookService }
