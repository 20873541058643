import styled from "styled-components"

import DriverPhotoPlaceHolderSVG from "../assets/driver-photo-placeholder.svg"
import EmptyHeartSVG from "../assets/empty-heart.svg"
import FilledHeartSVG from "../assets/filled-heart.svg"
import { SPACING } from "../../../component-library/design/spacing/spacing.constants"
import { COLOR } from "../../../component-library/design/color/color.constants"
import { BodyBold, SmallText } from "../../../component-library/design/typography/typography.styles"

const PHOTO_STYLES = `
  width: 36px;
  height: 36px;
  border-radius: 50%;
`

const ProfilePicture = styled.img`
  ${PHOTO_STYLES}
`

const DriverPhotoPlaceHolder = styled(DriverPhotoPlaceHolderSVG)`
  ${PHOTO_STYLES}
`

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
  align-items: center;
`

const DriverDetails = styled(BodyBold)`
  margin-bottom: ${SPACING.XXS};
`

const DriverInformation = styled.div`
  flex-grow: 1;
  svg {
    width: 20px;
    height: 20px;
  }
`

const EmptyHeart = styled(EmptyHeartSVG)`
  align-self: center;
  cursor: pointer;
`

const FilledHeart = styled(FilledHeartSVG)`
  align-self: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
`

const StyledPlate = styled(SmallText)`
  margin: 0;
  font-weight: 600;
  color: ${COLOR.GREY_DARKER};
  padding: 2px 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: inline-block;
`

export {
  Container,
  DriverInformation,
  ProfilePicture,
  DriverDetails,
  DriverPhotoPlaceHolder,
  EmptyHeart,
  FilledHeart,
  StyledPlate,
}
