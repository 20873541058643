import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Popover from '@mui/material/Popover'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

import {
  Body,
  SmallText,
} from 'ggx-componentlibrary/design/typography/typography.styles'

import {
  OpenShareMenuContainer,
  ShareMenuItem,
  StyledBody,
} from './order-sharing.component.styles'
import ShareSVG from '../assets/share.svg'
import SendOrderReceiptModal from './send-order-receipt-modal.component'

const OrderSharingMenu = ({
  $sm,
  uuid,
  whatsappURL,
  handleCopyLink,
  sendOrderReceipt,
  status,
}) => {
  const [t] = useTranslation()
  const [shareMenuAnchor, setShareMenuAnchor] = useState(null)
  const open = Boolean(shareMenuAnchor)
  const id = open ? 'order-sharing-menu' : undefined
  const IsOrderCompleted = status === 'completed'

  const handleOpen = useCallback(e => {
    setShareMenuAnchor(e.currentTarget)
  }, [])
  const handleClose = useCallback(() => {
    setShareMenuAnchor(null)
  }, [])

  const [showSendOrderReceiptModal, setShowSendOrderReceiptModal] = useState(
    false
  )
  const handleEmailButtonClick = useCallback(() => {
    if (!IsOrderCompleted) return
    setShowSendOrderReceiptModal(true)
    setShareMenuAnchor(null)
  }, [])

  return (
    <>
      {$sm ? (
        <Tooltip
          title={
            <SmallText typographyStyles="mb-none c-black">
              {t('share')}
            </SmallText>
          }
          placement="bottom"
          disableFocusListener
          disableTouchListener
          slotProps={{
            popper: {
              sx: {
                [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
                  marginTop: '4px',
                },
              },
            },
          }}
        >
          <OpenShareMenuContainer onClick={handleOpen} $open={open}>
            <ShareSVG />
          </OpenShareMenuContainer>
        </Tooltip>
      ) : (
        <OpenShareMenuContainer onClick={handleOpen} $open={open}>
          <ShareSVG />
          <Body typographyStyles="mb-none c-blue_medium">{t('share')}</Body>
        </OpenShareMenuContainer>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={shareMenuAnchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ marginTop: '10px' }}
      >
        <ShareMenuItem onClick={handleCopyLink}>
          <Body typographyStyles="mb-NONE">{t('button__copy_link')}</Body>
        </ShareMenuItem>
        <ShareMenuItem>
          <a href={whatsappURL} target="_blank" rel="noopener noreferrer">
            <Body typographyStyles="mb-NONE">{t('whatsapp')}</Body>
          </a>
        </ShareMenuItem>
        {!IsOrderCompleted ? (
          <Tooltip
            title={t('send_email_after_complete')}
            placement="bottom-start"
          >
            <StyledBody typographyStyles="mb-NONE" disabled={!IsOrderCompleted}>
              {t('common__email')}
            </StyledBody>
          </Tooltip>
        ) : (
          <ShareMenuItem onClick={handleEmailButtonClick}>
            <Body typographyStyles="mb-NONE">{t('common__email')}</Body>
          </ShareMenuItem>
        )}
      </Popover>
      {showSendOrderReceiptModal && (
        <SendOrderReceiptModal
          uuid={uuid}
          handleCancelSendOrderReceipt={() =>
            setShowSendOrderReceiptModal(false)
          }
          sendOrderReceipt={sendOrderReceipt}
        />
      )}
    </>
  )
}

OrderSharingMenu.propTypes = {
  $sm: PropTypes.bool,
  whatsappURL: PropTypes.string,
  status: PropTypes.string,
  handleCopyLink: PropTypes.func,
  sendOrderReceipt: PropTypes.func,
}

export default OrderSharingMenu
