import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

import {
  Body,
  SmallText,
} from 'ggx-componentlibrary/design/typography/typography.styles'
import { OpenShareMenuContainer } from './order-sharing.component.styles'
import PrintSVG from '../assets/print.svg'
import { LoadingSpinner } from 'ggx-src/legacy/views/helpers/loading/spinner'
import { SPACING } from 'ggx-componentlibrary/design/spacing/spacing.constants'
import { orderService } from 'ggx-src/van-booking/api/order-api.service'
import { getLocale } from 'ggx-src/legacy/utils/locale'

const PrintButton = ({ $sm, orderId }) => {
  const [t] = useTranslation()
  const [printing, setPrinting] = useState(false)
  const iframeRef = useRef()
  const handlePrint = async () => {
    if (iframeRef.current) {
      setPrinting(true)
      const { data } = await orderService.downloadOrderPDF(
        orderId,
        getLocale() === 'zh-hk' ? 'zh_hk' : 'en'
      )
      const blob = new Blob([data], { type: 'application/pdf' })
      const fileURL = window.URL.createObjectURL(blob)
      const timeId = setTimeout(() => setPrinting(false), 10000)
      iframeRef.current.onload = function() {
        this.contentWindow.print()
        setPrinting(false)
        clearTimeout(timeId)
      }
      iframeRef.current.src = fileURL
    }
  }

  return (
    <>
      {!printing ? (
        $sm ? (
          <Tooltip
            title={
              <SmallText typographyStyles="mb-none c-black">
                {t('print')}
              </SmallText>
            }
            placement="bottom"
            disableFocusListener
            disableTouchListener
            slotProps={{
              popper: {
                sx: {
                  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
                    marginTop: '4px',
                  },
                },
              },
            }}
          >
            <OpenShareMenuContainer onClick={handlePrint}>
              <PrintSVG />
            </OpenShareMenuContainer>
          </Tooltip>
        ) : (
          <OpenShareMenuContainer onClick={handlePrint}>
            <PrintSVG />
            <Body typographyStyles="mb-none c-blue_medium">{t('print')}</Body>
          </OpenShareMenuContainer>
        )
      ) : (
        <LoadingSpinner
          style={{
            width: SPACING.M,
            height: SPACING.M,
          }}
        />
      )}
      <iframe style={{ display: 'none' }} ref={iframeRef} />
    </>
  )
}

PrintButton.propTypes = {
  $sm: PropTypes.bool,
  orderId: PropTypes.string,
}

export default PrintButton
