import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  Body,
  BodyBold,
} from 'ggx-componentlibrary/design/typography/typography.styles'

import CardMasterIcon from 'ggx-src/van-booking/order/sidebar/order-form/summary/payment-method/assets/card_master_color.svg'
import CardVisaIcon from 'ggx-src/van-booking/order/sidebar/order-form/summary/payment-method/assets/card_visa_color.svg'
import CardUnionpayIcon from 'ggx-src/van-booking/order/sidebar/order-form/summary/payment-method/assets/card_unionpay_color.svg'
import CardAeIcon from 'ggx-src/van-booking/order/sidebar/order-form/summary/payment-method/assets/card_ae_color.svg'
import UnknownSVG from 'ggx-src/billing/payment-method/payment-methods-section/assets/unknown.svg'
import {
  StyledCreditCardPayment,
  PaymentMethodTitle,
} from 'ggx-src/van-booking/order/sidebar/order-form/summary/payment-method/payment-method.component.styles'

const CreditCardDisplay = ({ brand, last4, nickname, isExpired }) => {
  const [t] = useTranslation()
  return (
    <StyledCreditCardPayment>
      {(() => {
        switch (brand.toLowerCase()) {
          case 'amex':
            return <CardAeIcon />
          case 'mastercard':
            return <CardMasterIcon />
          case 'unionpay':
            return <CardUnionpayIcon />
          case 'visa':
            return <CardVisaIcon />
          default:
            return <UnknownSVG />
        }
      })()}
      <div>{nickname || t('default_card_name', { card_type: brand })}</div>
      <Body typographyStyles="mb-none c-grey_dark">⋅⋅⋅⋅ {last4}</Body>
    </StyledCreditCardPayment>
  )
}

CreditCardDisplay.displayName = 'CreditCardDisplay'

CreditCardDisplay.propTypes = {
  brand: PropTypes.string,
  last4: PropTypes.string,
  nickname: PropTypes.string,
  isExpired: PropTypes.bool,
}

export { CreditCardDisplay }
