import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  BodyBold,
  Body,
  SmallText,
} from 'ggx-componentlibrary/design/typography/typography.styles'
import { getCountry } from 'ggx-service/locale/country.service'
import Countries from 'ggx-src/legacy/constants/countries'

import {
  LearnMoreLink,
  IconExternalLinkIcon,
} from './payment-method.component.styles'
import { CreditCardDisplay as NewCreditCardDisplay } from './credit-card-display.component.js'

const PaymentMethod = ({ order }) => {
  const [t] = useTranslation()
  const creditIsOnHold = ['picked', 'pending', 'active'].includes(order.status)
  const creditCardHoldMessage = creditIsOnHold
    ? t('credit_card_temp_hold')
    : t('orderdetail.paymentMethod.description.creditCard2')

  const isPaidByCreditCard = order.payment_method === 'credit_card'
  const isMonthlySettlement = order.payment_method === 'cash_credit'
  const isVietnam = getCountry() === Countries.VIETNAM
  const hasCardInfo = order.card_info?.brand && order.card_info?.last4

  const getPaymentMethod = paymentMethod => {
    switch (paymentMethod) {
      case 'credit_card':
        return (
          <NewCreditCardDisplay
            brand={order.card_info?.brand}
            last4={order.card_info?.last4}
            noBorderDisplayMode
          />
        )
      case 'prepaid_wallet':
        return t('common__wallet')
      case 'cash_credit':
        return t('common__monthly_settlement')
      case 'cash':
        return t('payment.paymentMethods.cash')
      default:
        return ''
    }
  }

  if (isPaidByCreditCard && !hasCardInfo) {
    return null
  }

  return (
    <div>
      <BodyBold noMargin style={{ marginBottom: '12px' }}>
        {t('common__payment_method')}
      </BodyBold>
      <Body>{getPaymentMethod(order.payment_method)}</Body>
      {isPaidByCreditCard && (
        <>
          <SmallText noMargin style={{ display: 'inline' }}>
            {creditCardHoldMessage}
          </SmallText>
          &nbsp;
        </>
      )}
      {!isMonthlySettlement && !isVietnam && (
        <LearnMoreLink
          href={t('delivery__gogox_rebranding_url_learnmore')}
          target="_blank"
        >
          {t('orderdetail.paymentMethod.learnMoreLabel')}
        </LearnMoreLink>
      )}
    </div>
  )
}

PaymentMethod.propTypes = {
  order: PropTypes.object,
}

export { PaymentMethod }
