import { Component } from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import { setDocumentTitle } from 'ggx-service/document-title/set-document-title'
import { PageHeader } from '../helpers'

import TransactionTab from './transaction/tab'
import TransactionActionBar from './transaction/action_bar'
import DepositTab from './deposit/tab'
import DepositActionBar from './deposit/action_bar'
import WalletFilterView from './filter/filter.component'

import translate from '../../locales/translate'
import { RouteredOrderDetail } from '../../../order-management/order-detail-1/order-detail.component'
import Balance from './balance/balance.component'
import {
  WalletTabbedContainer,
  TabSection,
  TabContent,
  ActionBarContainer,
} from './tabbed_view.component.styles'
import { Color } from '../helpers'

import { track, MIXPANEL_EVENT } from 'ggx-service/mixpanel/mixpanel'
import { brazeClient, BRAZE_EVENT } from 'ggx-service/braze'

const translateWithPrefix = (key, options) =>
  translate(`wallet.view.${key}`, options)

export class WalletTabbedView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      activeTab: 'transactions',
      showLoading: true,
    }
  }

  componentDidMount() {
    const {
      location,
      getTransactions,
      getDepositTransactions,
      getOrderRequests,
      filterParams,
      organization,
    } = this.props

    getTransactions(filterParams)
    getDepositTransactions(filterParams)
    getOrderRequests(filterParams, organization)

    if (
      location &&
      location.pathname &&
      location.pathname.indexOf('/deposit') >= 0
    ) {
      this.setState({ activeTab: 'deposit' })
    }

    setDocumentTitle(translateWithPrefix('pageHeader'))
  }

  handleFilterChange = (filterParams = {}) => {
    const {
      setWalletFilter,
      getTransactions,
      getDepositTransactions,
    } = this.props

    setWalletFilter(filterParams)
    this.setState({ showLoading: true })
    getTransactions(filterParams)
    getDepositTransactions(filterParams)
  }

  handleTabChange = (evt, value) => {
    this.setState({
      activeTab: value,
    })
  }

  handleDownloadReport = () => {
    const {
      allTransactionIds,
      allDepositIds,
      downloadTransactionStatement,
      downloadDepositStatement,
    } = this.props
    if (this.state.activeTab === 'transactions') {
      downloadTransactionStatement(allTransactionIds)
    } else {
      downloadDepositStatement(allDepositIds)
    }

    track(MIXPANEL_EVENT.TRANSACTION_HISTORY.DOWNLOAD_REPORT, {
      type: this.state.activeTab,
    })
    brazeClient.setCustomEvent(
      BRAZE_EVENT.TRANSACTION_HISTORY.DOWNLOAD_REPORT,
      { type: this.state.activeTab }
    )
  }

  tabContent() {
    const {
      depositTransactions,
      loading,
      transactions,
      included,
      router,
    } = this.props
    const { activeTab } = this.state

    return activeTab === 'transactions' ? (
      <TransactionTab
        transactions={transactions}
        included={included}
        loading={loading}
        openOrderRequestDialog={orderRequestId =>
          router.push(`/wallet/${orderRequestId}`)
        }
      />
    ) : (
      <DepositTab depositTransactions={depositTransactions} loading={loading} />
    )
  }

  actionBar() {
    const { activeTab } = this.state
    const activeTabBar =
      activeTab === 'transactions' ? (
        <TransactionActionBar />
      ) : (
        <DepositActionBar />
      )
    return activeTabBar
  }

  render() {
    const { filterParams, organization } = this.props
    const tabsSx = {
      '&.MuiTabs-root': {
        padding: '0 20px',
      },
      '& .MuiTab-root.Mui-selected': {
        color: 'black',
      },
      '& .MuiTabs-indicator': {
        backgroundColor: 'transparent',
      },
    }
    const transactionsTabSx = {
      color: Color.BLACK,
      backgroundColor:
        this.state.activeTab === 'transactions' ? Color.WHITE : Color.GEYSER,
    }

    const depositTabSx = {
      color: Color.BLACK,
      backgroundColor:
        this.state.activeTab === 'deposit' ? Color.WHITE : Color.GEYSER,
    }

    return (
      <>
        <WalletTabbedContainer>
          <div>
            <PageHeader
              title={translateWithPrefix('pageHeader')}
              additionalNodes={
                <Balance
                  organization={organization}
                  handleDownloadReport={this.handleDownloadReport}
                />
              }
            />
            <WalletFilterView
              onChanged={this.handleFilterChange}
              filterParams={filterParams || { year: '', month: '' }}
            />

            <TabSection>
              <Tabs
                sx={tabsSx}
                value={this.state.activeTab}
                onChange={this.handleTabChange}
              >
                <Tab
                  label={translateWithPrefix('transaction')}
                  value="transactions"
                  sx={transactionsTabSx}
                />
                <Tab
                  label={translateWithPrefix('deposit')}
                  value="deposit"
                  sx={depositTabSx}
                />
              </Tabs>
              <TabContent>{this.tabContent()}</TabContent>
            </TabSection>
          </div>
          <ActionBarContainer>{this.actionBar()}</ActionBarContainer>
        </WalletTabbedContainer>
        <RouteredOrderDetail />
      </>
    )
  }
}

WalletTabbedView.propTypes = {
  filterParams: PropTypes.object,
  organization: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.object,
  transactions: PropTypes.arrayOf(PropTypes.object),
  depositTransactions: PropTypes.arrayOf(PropTypes.object),
  getTransactions: PropTypes.func,
  getDepositTransactions: PropTypes.func,
  getOrderRequests: PropTypes.func,
  setWalletFilter: PropTypes.func,
  downloadTransactionStatement: PropTypes.func,
  downloadDepositStatement: PropTypes.func,
  allTransactionIds: PropTypes.arrayOf(PropTypes.number),
  allDepositIds: PropTypes.arrayOf(PropTypes.number),
}

export default WalletTabbedView
