import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { GoogleMap, LoadScriptNext, Marker } from '@react-google-maps/api'

import {
  StaticTrackerMap,
  MapContainer,
  orderMapContainerDimensions,
  GoogleMapWrapper,
} from './order-map.component.styles'
import {
  getMapCenter,
  getDefaultZoom,
} from 'ggx-src/van-booking/order/map/map.service'

import PickUpMarker from './assets/pick_up.png'
import DropOffMarker from './assets/drop_off.png'
import DriverPhotoPlaceHolder from './assets/driver-photo-placeholder.png'

const OrderMap = ({ order }) => {
  const linkedOrder = order.order || {}
  const defaultZoom = getDefaultZoom()
  const mapCenter = getMapCenter()

  const protocolHostname = `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port ? `:${window.location.port}` : ''}`
  const mapRef = useRef(null)

  const pickUpMarkerURL = protocolHostname + PickUpMarker
  const dropOffMarkerURL = protocolHostname + DropOffMarker
  const driverPhotoURL = window.google
    ? {
        url: protocolHostname + DriverPhotoPlaceHolder,
        scaledSize: new google.maps.Size(70, 70),
      }
    : null

  const getTrackerMapURL = ({ waypoints, status }) => {
    if (waypoints?.length >= 1) {
      const mapParams = [
        'size=322x216',
        'maptype=roadmap',
        'scale=2',
        `markers=icon:${pickUpMarkerURL}%7C${waypoints?.[0].lat},${waypoints?.[0].lon}`,
        `key=${ENV.GOOGLE_MAP_KEY}`,
      ]

      if (status === 'pending') {
        mapParams.push(
          `center=${waypoints?.[0].lat + 0.015},${waypoints?.[0].lon}`,
          'zoom=12'
        )
      } else {
        waypoints
          .slice(1)
          .map(waypoint =>
            mapParams.push(
              `markers=icon:${dropOffMarkerURL}%7C${waypoint.lat},${waypoint.lon}`
            )
          )
      }

      const trackerMapURL =
        'https://maps.googleapis.com/maps/api/staticmap?' + mapParams.join('&')

      return trackerMapURL
    }
    return null
  }

  const setMapZoom = waypoints => {
    if (!window.google || !mapRef.current) return null

    const markers = waypoints
      .filter(
        waypoint =>
          typeof waypoint.lat === 'number' && typeof waypoint.lon === 'number'
      )
      .map(waypoint => ({ lat: waypoint.lat, lng: waypoint.lon }))

    if (linkedOrder.driver_location) {
      markers.push({
        lat: linkedOrder.driver_location.latitude,
        lng: linkedOrder.driver_location.longitude,
      })
    }

    const numberOfWaypoint = markers.length

    if (numberOfWaypoint > 1) {
      const bounds = new window.google.maps.LatLngBounds()

      markers.forEach(marker => {
        bounds.extend(marker)
      })
      mapRef.current.fitBounds(bounds)
    } else if (numberOfWaypoint === 1) {
      const { lat, lng } = markers[0]

      mapRef.current.setCenter({ lat, lng })
      mapRef.current.setZoom(13)
    }

    return null
  }

  const mapStyles = [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
  ]

  useEffect(() => {
    setMapZoom(order.waypoints)
  })

  return (
    <MapContainer>
      {order.status === 'active' ? (
        <GoogleMapWrapper>
          <LoadScriptNext googleMapsApiKey={ENV.GOOGLE_MAP_KEY}>
            <GoogleMap
              center={mapCenter}
              mapContainerStyle={orderMapContainerDimensions}
              zoom={defaultZoom}
              options={{
                mapTypeControl: false,
                streetViewControl: false,
                styles: mapStyles,
              }}
              onLoad={map => {
                if (!mapRef.current) {
                  mapRef.current = map
                  setMapZoom(order.waypoints)
                }
              }}
            >
              {order.waypoints
                .filter(waypoint => waypoint.lat && waypoint.lon)
                .map((waypoint, index) => {
                  const iconURL =
                    index === 0 ? pickUpMarkerURL : dropOffMarkerURL
                  return (
                    <Marker
                      key={`marker-${index}`}
                      position={{
                        lat: waypoint.lat,
                        lng: waypoint.lon,
                      }}
                      icon={iconURL}
                    />
                  )
                })}
              {linkedOrder?.driver_location && (
                <Marker
                  position={{
                    lat: linkedOrder.driver_location.latitude,
                    lng: linkedOrder.driver_location.longitude,
                  }}
                  icon={driverPhotoURL}
                />
              )}
            </GoogleMap>
          </LoadScriptNext>
        </GoogleMapWrapper>
      ) : (
        <StaticTrackerMap
          src={getTrackerMapURL({
            waypoints: order.waypoints,
            status: order.status,
          })}
        />
      )}
    </MapContainer>
  )
}

OrderMap.defaultProps = {
  order: {
    waypoints: [],
    status: '',
  },
}

OrderMap.propTypes = {
  order: PropTypes.shape({
    waypoints: PropTypes.array,
    status: PropTypes.string,
  }),
}

export { OrderMap }
