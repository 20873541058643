import styled from "styled-components"

import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import { COLOR } from "ggx-componentlibrary/design/color/color.constants"
import { Body } from "ggx-componentlibrary/design/typography/typography.styles"
import { CardDisplayWrapper } from "ggx-componentlibrary/components/credit-card-display/credit-card-display.component.styles"
import { Heading } from 'ggx-componentlibrary/design/typography/typography.styles'

import RightIconSVG from "./assets/right_icon.svg"

const PaymentContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: ${SPACING.S};
  align-items: center;
`

const PaymentMethodContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const PaymentMethodSelection = styled.div`
  display: flex;
  align-items: center;

  background-color: ${COLOR.GREY_LIGHTEST};
  padding: ${SPACING.XS};
  border-radius: ${SPACING.XXS};

  ${Body} {
    color: ${COLOR.GREY_DARK};
  }

  path {
    stroke: ${COLOR.GREY_DARK};
  }

  ${(props) =>
    props.canSwitchPaymentMethod &&
    `
    cursor: pointer;
    padding: ${SPACING.XXS} ${SPACING.XXS} ${SPACING.XXS} ${SPACING.XS};

    :hover {
      background-color: ${COLOR.GREY_LIGHTER};
      
      ${Body}, ${CardDisplayWrapper} {
        color: ${COLOR.BLACK};
      }

      path {
        stroke: ${COLOR.BLACK};
      }
    }
  `}
`

const RightIcon = styled(RightIconSVG)`
  margin-left: ${SPACING.XS};
`

const TotalContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-end;
`

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  column-gap: ${SPACING.S};
`

const StyledHeading = styled(Heading)`
    display: flex;
    align-items: center;
    cursor: pointer;
`

const StyledCreditCardPayment = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const PaymentMethodTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  &:last-child {
    margin-bottom: 0;
  }
`

export {
  PaymentContainer,
  PaymentMethodContainer,
  PaymentMethodSelection,
  RightIcon,
  TotalContainer,
  ButtonsContainer,
  StyledHeading,
  StyledCreditCardPayment,
  PaymentMethodTitle,
}
