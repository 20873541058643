import styled, { css } from "styled-components"

import { COLOR } from "ggx-componentlibrary/design/color/color.constants"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import { TextArea } from "ggx-componentlibrary/components/textarea/textarea.component"
import { BodyBold } from "ggx-componentlibrary/design/typography/typography.styles"
import OrderDetailCross from "../assets/order-detail-cross.svg"
import { InputStyles } from "ggx-componentlibrary/components/text-field/text-field.component.styles"
import { FONT_SIZE } from "ggx-componentlibrary/design/typography/typography.constants"

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${COLOR.GREY_LIGHTER};
  border-radius: 8px;
  padding: ${SPACING.S};
`

const StyledBodyBold = styled(BodyBold)`
  text-align: center;
  margin-top: ${SPACING.M};
`

const StyledTextArea = styled.textarea`
    ${props => InputStyles({ error: props.error, ...props })}
    height: auto;
    padding: 12px ${SPACING.S};
    resize: none;
    line-height: ${FONT_SIZE.LARGE};
    width: 100%;
`

const StyledOrderDetailCross = styled(OrderDetailCross)`
  position: absolute;
  cursor: pointer;
  width: 16px;
  height: 16px;
  right: 16px;
`

const overlayClass = css`
  z-index: 9999;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
`

export {
  Wrapper,
  StyledBodyBold,
  StyledTextArea,
  StyledOrderDetailCross,
  overlayClass,
  Container,
  InnerContainer,
}
