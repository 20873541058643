import styled from 'styled-components'
import { Body } from 'ggx-componentlibrary/design/typography/typography.styles'

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const Link = styled(Body)`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`

export { Container, Link }
