import styled from "styled-components"
import { COLOR } from "ggx-componentlibrary/design/color/color.constants"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import {
  FONT_SIZE,
  FONT_BOLD,
} from "ggx-componentlibrary/design/typography/typography.constants"
import pickupPin from "ggx-componentlibrary/components/waypoint/assets/location-start.svg"
import dropoffPin from "ggx-componentlibrary/components/waypoint/assets/location.svg"
import { BodySemiBold } from "ggx-componentlibrary/design/typography/typography.styles"

const iconHeightWidth = "24px"
const iconPinMarginRight = SPACING.XS
const iconRightMarginLeft = SPACING.XS

const iconStyles = `
  background-color: ${COLOR.WHITE};
  margin-right: ${iconPinMarginRight};
  flex-grow: 0;
  flex-shrink: 0;
  width: ${iconHeightWidth};
  background: transparent;
`

const PickupPinIcon = styled(pickupPin)`
  ${iconStyles}
`

const DropoffPinIcon = styled(dropoffPin)`
  ${iconStyles}
`

const CustomIconContainer = styled.div`
  ${iconStyles}
`

const WaypointTrailStyles = `
  content: '';
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 11px;
  border-left-width: 2px;
  border-left-color: ${COLOR.BLACK};
  z-index: -1;
`

const WaypointTrailBorderStyleDefault = `
  border-left-style: dashed;
`

const WaypointTrailBorderStyleSolid = `
  border-left-style: solid;
`

const WaypointTitleWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  padding-bottom: ${(props) => (props.hasCaption ? SPACING.S : 0)};
  width: 100%;
`

const WaypointTitle = styled(BodySemiBold)`
  flex-grow: 1;
  margin-bottom: 8px;
`

const WaypointCaption = styled.span`
  ${FONT_BOLD};
  font-size: ${FONT_SIZE.MEDIUM};
`

const WaypointIconRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: ${iconHeightWidth};
  cursor: pointer;
  & > * {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
`

const WaypointAnchorWrapper = styled.div`
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  &:before {
    ${WaypointTrailStyles}
    ${(props) =>
      props.trailSolid
        ? WaypointTrailBorderStyleSolid
        : WaypointTrailBorderStyleDefault}
    ${(props) => props.trailColor && `border-left-color: ${props.trailColor}`};
    top: 0;
    ${(props) => props.isDestination && "border: none"}
  }

  padding-left: 39px;
`

const WaypointContentWrapper = styled.div`
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  &:before {
    ${WaypointTrailStyles}
    ${(props) =>
      props.trailSolid
        ? WaypointTrailBorderStyleSolid
        : WaypointTrailBorderStyleDefault}
    ${(props) => props.trailColor && `border-left-color: ${props.trailColor}`};
    top: 2px;
    ${(props) => props.isDestination && "border: none"}
  }

  padding-left: 32px;
  padding-bottom: ${(props) => (props.isDestination ? 0 : SPACING.M)};
`

const WaypointWrapper = styled.div`
  position: relative;

  ${WaypointTitleWrapper} {
    background: ${(props) => (props.isDragging ? "white" : "none")};
    opacity: ${(props) => (!props.draggingOver && props.isDragging ? 0.3 : 1)};
    &:before {
      display: ${(props) => (props.isDragging ? "none" : "block")};
    }
  }

  ${WaypointContentWrapper} {
    background: ${(props) => (props.isDragging ? "white" : "none")};
    opacity: ${(props) => (!props.draggingOver && props.isDragging ? 0.3 : 1)};
    &:before {
      display: ${(props) => (props.isDragging ? "none" : "block")};
    }
  }
`

export {
  iconStyles,
  WaypointWrapper,
  WaypointTitleWrapper,
  WaypointTitle,
  WaypointCaption,
  WaypointIconRight,
  WaypointAnchorWrapper,
  WaypointContentWrapper,
  PickupPinIcon,
  DropoffPinIcon,
  CustomIconContainer,
}
