import styled from "styled-components"
import { COLOR } from "ggx-componentlibrary/design/color/color.constants"
import { Heading, SmallText } from "ggx-componentlibrary/design/typography/typography.styles"

const StyledHeading = styled(Heading)`
  margin-bottom: 12px;
`

const StyledSmallText = styled(SmallText)`
  color: ${COLOR.BLUE_MEDIUM};
  margin: 0;
  cursor: pointer;
`

export { StyledHeading, StyledSmallText }
