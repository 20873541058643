import {
  RevampVanBookingRoutes,
  MonthlySettlementWalletRoutes,
  BillingHistoryRoute,
  VoucherRoute,
  PaymentMethodRoute,
} from './van-booking/van-booking.routes'
import { DeliveryRoutes } from './delivery/delivery.routes'
import { HomeRoutes } from './home/home.routes'
import { UsersAndBranchesRoutes } from './users-and-branches/users-and-branches.routes'
import { DeveloperRoutes } from './developer/developer.routes'
import { ProfileRoutes } from './profile/profile.routes'
import { InventoryRoutes } from './inventory/inventory.routes'
import { NextDayDeliveryRoutes } from './next-day-delivery/next-day-delivery.routes'
import { AddressBookRoutes } from './address-book/address-book.routes'

const AppRoutes = store => (
  <div>
    {RevampVanBookingRoutes(store)}
    {DeliveryRoutes(store)}
    {MonthlySettlementWalletRoutes(store)}
    {HomeRoutes(store)}
    {BillingHistoryRoute(store)}
    {VoucherRoute(store)}
    {PaymentMethodRoute(store)}
    {UsersAndBranchesRoutes(store)}
    {DeveloperRoutes(store)}
    {ProfileRoutes(store)}
    {InventoryRoutes(store)}
    {NextDayDeliveryRoutes(store)}
    {AddressBookRoutes(store)}
  </div>
)

export { AppRoutes }
