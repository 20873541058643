import styled from "styled-components"

import { Button } from "ggx-componentlibrary/components/button/button.component"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import { TextField } from "ggx-componentlibrary/components/text-field/text-field.component"
import { BodyBold } from 'ggx-componentlibrary/design/typography/typography.styles'

const StyledButton = styled(Button)` 
`

const FieldWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${SPACING.S};
  > *:nth-child(2) {
    flex: 1 0 70%;
  }
  margin-bottom: ${SPACING.S};
  &:last-child {
    margin-bottom: 0;
  }
`

const Label = styled(BodyBold)`
  margin-bottom: 0;
  width: 136px;
`

const Row = styled.div`
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: ${SPACING.S};
  > * {
    margin-right: 0;
  }
  .MuiAutocomplete-inputRoot {
    height: 40px;
  }
`

const Rows = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.S};
`

const StyledTextField = styled(TextField)`
  ${(props) => {
    switch (props.input?.name || props.name) {
      case "detail_address":
        return `width: 41.45%;`
      default:
        return "width: 100%;"
    }
  }}
   input {
    width: 100%
  }
`

export { StyledButton, FieldWrapper, Row, Rows, StyledTextField, Label }
