import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Rating } from './rating/rating.component'
import {
  Wrapper,
  StyledTextArea,
  StyledOrderDetailCross,
  overlayClass,
  Container,
  InnerContainer,
} from './driver-rating.component.styles'
import {
  SmallTextSemiBold,
  BodySemiBold,
} from 'ggx-componentlibrary/design/typography/typography.styles'
import { orderService } from 'ggx-src/van-booking/api/order-api.service'
import { displayAlert } from 'ggx-componentlibrary/components/alerts/alerts.component'
import DriverRatingOptions from './options/options.component'
import { Modal } from 'ggx-componentlibrary/components/modal/modal.component'
import { Button } from 'ggx-componentlibrary/components/button/button.component'
import { DriverPhoto } from '../driver-info/driver-info.component'

const CLIENT_FEEDBACK_TYPE = {
  driving: 1,
  navigation: 2,
  politeness: 3,
  punctuality: 4,
  vehicle_quality: 5,
  charges: 6,
  incorrect_driver_info: 7,
  helpfulness: 8,
  cool_music: 9,
  other: 10,
}

const CLIENT_BAD_FEEDBACK_OPTIONS = {
  [CLIENT_FEEDBACK_TYPE.driving]: 'text__driver_feedback__driving',
  [CLIENT_FEEDBACK_TYPE.navigation]: 'text__driver_feedback__navigation',
  [CLIENT_FEEDBACK_TYPE.politeness]:
    'chip__driver_feedback__politeness__non_5_star',
  [CLIENT_FEEDBACK_TYPE.punctuality]:
    'chip__driver_feedback__punctuality__non_5_star',
  [CLIENT_FEEDBACK_TYPE.vehicle_quality]:
    'text__driver_feedback__vehicle_quality',
  [CLIENT_FEEDBACK_TYPE.charges]: 'text__driver_feedback__charges',
  [CLIENT_FEEDBACK_TYPE.incorrect_driver_info]:
    'text__driver_feedback__incorrect_license_plate',
  [CLIENT_FEEDBACK_TYPE.other]: 'common__other',
}

const CLIENT_GOOD_FEEDBACK_OPTIONS = {
  [CLIENT_FEEDBACK_TYPE.driving]: 'text__driver_feedback__driving',
  [CLIENT_FEEDBACK_TYPE.navigation]: 'text__driver_feedback__navigation',
  [CLIENT_FEEDBACK_TYPE.politeness]: 'text__driver_feedback__politeness',
  [CLIENT_FEEDBACK_TYPE.punctuality]: 'text__driver_feedback__punctuality',
  [CLIENT_FEEDBACK_TYPE.vehicle_quality]:
    'text__driver_feedback__vehicle_quality',
  [CLIENT_FEEDBACK_TYPE.helpfulness]: 'text__driver_feedback__helpfulness',
  [CLIENT_FEEDBACK_TYPE.cool_music]: 'text__driver_feedback__cool_music',
  [CLIENT_FEEDBACK_TYPE.other]: 'common__other',
}

const DriverRating = ({
  orderId,
  name,
  rating,
  driverPhoto,
  onRatingSuccess,
  onClose,
  simpleMode = false,
}) => {
  const [t] = useTranslation()
  const [ratingSelected, setRatingSelected] = useState(rating)
  const [feedback, setFeedback] = useState(null)
  const [selectedOptions, setSelectedOptions] = React.useState([])
  const [submitting, setSubmitting] = useState(false)
  const buttonSendOnClickHandler = async () => {
    try {
      setSubmitting(true)
      await orderService.rateDriver({
        idOrderRequest: orderId,
        feedbackTypeCds: selectedOptions,
        driverRating: ratingSelected,
        clientFeedback: feedback,
      })

      displayAlert({ message: t('notification__order_rated'), autoClose: true })
      onRatingSuccess()
    } catch (error) {
      displayAlert({
        message: t('errors.generic'),
        autoClose: true,
        type: 'error',
      })
    }
  }

  const handleChange = event => {
    const nameToNum = Number(event.target.name)

    if (event.target.checked) {
      if (selectedOptions.includes(nameToNum)) return

      setSelectedOptions([...selectedOptions, nameToNum])
    } else {
      setSelectedOptions(
        selectedOptions.filter(feedback => feedback !== nameToNum)
      )
    }
  }

  const cancelButtonOnClickHandler = () => {
    setRatingSelected(null)
    setSelectedOptions([])
  }

  const handleRatingSelected = rating => {
    if (rating === 5) {
      setSelectedOptions(
        selectedOptions.filter(
          feedback =>
            ![
              CLIENT_FEEDBACK_TYPE.charges,
              CLIENT_FEEDBACK_TYPE.incorrect_driver_info,
            ].includes(feedback)
        )
      )
    } else {
      setSelectedOptions(
        selectedOptions.filter(
          feedback =>
            ![
              CLIENT_FEEDBACK_TYPE.helpfulness,
              CLIENT_FEEDBACK_TYPE.cool_music,
            ].includes(feedback)
        )
      )
    }

    setRatingSelected(rating)
  }

  const ratingBar = (
    <>
      <Rating
        initialRating={ratingSelected}
        onRatingClick={handleRatingSelected}
        disabled={rating != null}
      />
      {rating == null && ratingSelected && (
        <Modal
          height="initial"
          width="600px"
          minHeight="auto"
          header={t('rate_order')}
          footer={
            <>
              <Button
                onClick={cancelButtonOnClickHandler}
                buttonType="secondary"
              >
                {t('common__cancel')}
              </Button>
              <Button onClick={submitting ? null : buttonSendOnClickHandler}>
                {t('btn__send')}
              </Button>
            </>
          }
          onRequestClose={cancelButtonOnClickHandler}
          overlayClass={overlayClass}
        >
          <Container>
            <InnerContainer>
              <DriverPhoto src={driverPhoto} />
              <BodySemiBold noMargin>
                {t('text__please_rate_driver_service', { name })}
              </BodySemiBold>
              <Rating
                initialRating={ratingSelected}
                onRatingClick={handleRatingSelected}
              />
            </InnerContainer>
            <DriverRatingOptions
              ratingSelected={ratingSelected}
              selectedOptions={selectedOptions}
              handleChange={handleChange}
            />
            <StyledTextArea
              rows="1"
              placeholder={t('add_a_comment')}
              onChange={value => setFeedback(value)}
            />
          </Container>
        </Modal>
      )}
    </>
  )

  return !simpleMode ? (
    <Wrapper>
      <StyledOrderDetailCross onClick={onClose} />
      <SmallTextSemiBold
        noMargin
        typographyStyles="c-black"
        style={{ marginBottom: '6px' }}
      >
        {t('text__please_rate_driver_service', { name })}
      </SmallTextSemiBold>
      {ratingBar}
    </Wrapper>
  ) : (
    ratingBar
  )
}

DriverRating.propTypes = {
  orderId: PropTypes.string,
  name: PropTypes.string,
  rating: PropTypes.number,
  driverPhoto: PropTypes.string,
  onRatingSuccess: PropTypes.func,
  onClose: PropTypes.func,
  simpleMode: PropTypes.bool,
}

export {
  DriverRating,
  CLIENT_FEEDBACK_TYPE,
  CLIENT_BAD_FEEDBACK_OPTIONS,
  CLIENT_GOOD_FEEDBACK_OPTIONS,
}
