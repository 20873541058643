import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import pDebounce from 'p-debounce'

import StarSelected from '../../assets/star-selected.svg'
import StarUnselected from '../../assets/star-unselected.svg'
import { NUMBERSTARS } from '../../order-heading/order-heading.component'

const Rating = ({ initialRating, onRatingClick, disabled }) => {
  const [ratingSelected, setRatingSelected] = useState(initialRating)
  const debouncedSetRatingSelected = pDebounce(setRatingSelected, 10)

  useEffect(() => {
    setRatingSelected(initialRating)
  }, [initialRating])

  const ratingComponentRef = React.useRef()
  const currentValOfRatingRef = ratingComponentRef.current

  const onMouseOutRatingComponent = () => {
    debouncedSetRatingSelected(initialRating)
  }

  // This is used to prevent no mouse-out event fired when the DOM element is updating,
  // which is occured when mouse is moving too fast
  setTimeout(() => {
    if (
      currentValOfRatingRef &&
      currentValOfRatingRef.matches(':hover') === false
    ) {
      onMouseOutRatingComponent()
    }
  }, 10)

  return (
    <div
      ref={ratingComponentRef}
      onMouseOut={onMouseOutRatingComponent}
      style={{ display: 'flex' }}
    >
      {NUMBERSTARS.map(index => {
        const starProps = {
          onMouseOver: () => debouncedSetRatingSelected(index),
          onClick: () => onRatingClick(index),
          key: `rating-${index}`,
        }

        if (index <= ratingSelected) {
          // data-testid is added because jest was not able to
          // differentiate between StarSelected and StarUnselected
          return (
            <StarSelected
              {...(disabled ? {} : starProps)}
              data-testid="star-selected"
            />
          )
        }

        return (
          <StarUnselected
            {...(disabled ? {} : starProps)}
            data-testid="star-unselected"
          />
        )
      })}
    </div>
  )
}

Rating.propTypes = {
  initialRating: PropTypes.number,
  onRatingClick: PropTypes.func,
}

Rating.defaultProps = {
  onRatingClick: () => {},
}

export { Rating }
