import React, { useState } from 'react'
import PropTypes from 'prop-types'
import withRouter from 'react-router/lib/withRouter'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { Button } from '../../../component-library/components/button/button.component'
import { orderService } from '../../../van-booking/api/order-api.service'
import { OrderCancelModal } from '../order-cancel-modal/order-cancel-modal.component'
import { ORDER_MANAGEMENT_KEY } from '../../order-management.component'
import { displayAlert } from 'ggx-componentlibrary/components/alerts/alerts.component'
import { Container, Link } from './order-actions.component.style'
import CloseSVG from './assets/close.svg'
import ReloadSVG from './assets/reload.svg'

const OrderActions = ({
  orderId,
  orderUuid,
  orderContactPhoneNumber,
  waypoints,
  status,
  router,
  onOrderCancelled,
  breakdown,
}) => {
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const [showCancelModal, setShowCancelModal] = useState(false)

  const waypointsWithAddressDetails = waypoints.map(
    ({
      address,
      lat,
      lon,
      contact_name,
      contact_phone_number,
      detail_address,
    }) => ({
      address,
      lat,
      lon,
      contact_name,
      contact_phone_number: contact_phone_number || orderContactPhoneNumber,
      detail_address,
    })
  )

  const handleReuseWaypoints = () => {
    router.push({
      pathname: '/van/order',
      state: {
        waypoints: waypointsWithAddressDetails,
        breakdown,
      },
    })
  }

  const handleCancelOrder = async () => {
    try {
      setShowCancelModal(false)
      await orderService.cancelOrder(orderUuid)
      onOrderCancelled({ id: orderId })
      queryClient.invalidateQueries(ORDER_MANAGEMENT_KEY)
    } catch (error) {
      const statusCode = error?.response?.status

      if (statusCode === 409 || statusCode === 404) {
        displayAlert({
          message: error?.response?.data?.extras?.error_msg,
          type: 'error',
        })
      } else {
        displayAlert({ message: t('errors.generic'), type: 'error' })
      }
    }
  }

  return (
    <Container>
      {status === 'pending' ? (
        <>
          <Link
            data-cy="cancel-order-btn"
            onClick={() => setShowCancelModal(true)}
            typographyStyles="c-red_medium"
            noMargin
          >
            <CloseSVG />
            <span>{t('button__cancel_order')}</span>
          </Link>
          {showCancelModal && (
            <OrderCancelModal
              handleCancelOrder={handleCancelOrder}
              handleModalClose={() => setShowCancelModal(false)}
            />
          )}
        </>
      ) : null}
      <Link
        onClick={handleReuseWaypoints}
        typographyStyles="c-blue_medium"
        noMargin
      >
        <ReloadSVG />
        <span>{t('btn__reuse_route_in_new_order')}</span>
      </Link>
    </Container>
  )
}

OrderActions.propTypes = {
  orderId: PropTypes.string,
  orderUuid: PropTypes.string,
  waypoints: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string,
      lat: PropTypes.number,
      lon: PropTypes.number,
      contact_name: PropTypes.string,
      contact_phone_number: PropTypes.string,
      detail_address: PropTypes.string,
    })
  ),
  status: PropTypes.string,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
  onOrderCancelled: PropTypes.func,
  breakdown: PropTypes.object,
}

OrderActions.defaultProps = {
  waypoints: [],
}

const RouteredOrderActions = withRouter(OrderActions)

export { RouteredOrderActions, OrderActions }
