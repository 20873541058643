import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { StyledHeading, StyledSmallText } from './order-status.component.styles'
import { SmallText } from '../../../component-library/design/typography/typography.styles'
import { formatDateTime } from '../../../service/date-time/format-date-time'
import { StatusIndicator } from './status-indicator/status-indicator.component'

const OrderStatus = ({ order }) => {
  const [t] = useTranslation()

  const getDropOffLabel = (dropOffIndex, dropOffCount) => {
    if (dropOffCount === 1)
      return t('status__arrived_at_drop-off_point_number', {
        number: '',
      })

    return t('status__arrived_at_drop-off_point_number', {
      number: dropOffCount - dropOffIndex,
    })
  }

  const status = [
    order.status === 'cancelled' && (
      <StatusIndicator status={t('status__order_cancelled')} isCross>
        <SmallText noMargin>{formatDateTime(order.updated_at)}</SmallText>
      </StatusIndicator>
    ),
    order.status === 'completed' && (
      <StatusIndicator status={t('status__order_completed')}>
        <SmallText noMargin>{formatDateTime(order.resolved_at)}</SmallText>
      </StatusIndicator>
    ),
    ...(order.waypoints
      ?.slice(1)
      .reverse()
      .map((dropOffPoint, index) => {
        if (dropOffPoint.driver_arrived_at) {
          return (
            <StatusIndicator
              key={order.waypoints.length - 1 - index}
              status={getDropOffLabel(index, order.waypoints.length - 1)}
            >
              <SmallText noMargin>
                {formatDateTime(dropOffPoint.driver_arrived_at)}
              </SmallText>
            </StatusIndicator>
          )
        }

        return null
      }) ?? []),
    order.waypoints && order.waypoints[0].driver_arrived_at && (
      <StatusIndicator status={t('status__arrived_at_pick-up_point')}>
        <SmallText noMargin>
          {formatDateTime(order.waypoints[0].driver_arrived_at)}
        </SmallText>
      </StatusIndicator>
    ),
    order.order?.created_at && (
      <StatusIndicator status={t('status__accepted_by_driver')}>
        <SmallText noMargin>{formatDateTime(order.order.created_at)}</SmallText>
      </StatusIndicator>
    ),
    <StatusIndicator status={t('status__order_placed')}>
      <SmallText noMargin>{formatDateTime(order.created_at)}</SmallText>
    </StatusIndicator>,
  ].filter(Boolean)
  const [showMore, setShowMore] = useState(false)
  return (
    <div>
      <StyledHeading>{t('common__status')}</StyledHeading>
      <div>
        {status.slice(0, showMore ? undefined : 4)}
        {!showMore && status.length > 4 ? (
          <StyledSmallText onClick={() => setShowMore(true)}>
            {t('more_update', { number: status.length - 4 })}
          </StyledSmallText>
        ) : null}
      </div>
    </div>
  )
}

OrderStatus.defaultProps = {
  order: {
    waypoints: [],
    status: '',
  },
}

OrderStatus.propTypes = {
  order: PropTypes.shape({
    waypoints: PropTypes.array,
    status: PropTypes.string,
  }),
}

export { OrderStatus }
