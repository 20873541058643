import styled from "styled-components"

import { COLOR } from 'ggx-componentlibrary/design/color/color.constants'
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import { Heading, TextLinkStyles, Body } from "ggx-componentlibrary/design/typography/typography.styles"

const StyledHeading = styled(Heading)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${SPACING.M};
  margin-bottom: ${SPACING.M};
`

const DriverNotesContainer = styled.div`
  margin-bottom: ${SPACING.L};
  &:last-child {
    margin-bottom: 0;
  }
`

const PrintButton = styled.p`
  ${TextLinkStyles}
  display: flex;
  align-items: center;
  gap: ${SPACING.XS};
  font-size: 14px;
  margin: 0;
`

const StyledNote = styled(Body)`
  word-wrap: break-word;
`

export { StyledHeading, DriverNotesContainer, PrintButton, StyledNote }
