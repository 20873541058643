import styled from "styled-components"

import { COLOR } from "ggx-componentlibrary/design/color/color.constants"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import { Loader } from "ggx-componentlibrary/components/loader/loader.component"
import {
  BodyBold,
  Heading,
} from "ggx-componentlibrary/design/typography/typography.styles"

const StaticTrackerMap = styled.img`
  position: relative;
  width: 100%;
  border-radius: 6px;
`

const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LoadingMessageWrapper = styled.div`
  display: flex;
  position: absolute;
  height: ${SPACING.L};
  align-items: center;
  z-index: 1;
  background-color: ${COLOR.WHITE};
  transform: translateY(75px);
  border-radius: ${SPACING.XS};
  box-shadow: 0px ${SPACING.XXS} ${SPACING.XXS} rgba(0, 0, 0, 0.1);
`

const StyledLoader = styled(Loader)`
  width: 18px;
  height: 18px;
  margin-left: ${SPACING.S};
`
const StyledHeading = styled(Heading)`
  margin-bottom: 18px;
`

const GoogleMapWrapper = styled.div``

const orderMapContainerDimensions = {
  height: "215px",
  width: "320px",
}

export {
  StaticTrackerMap,
  MapContainer,
  LoadingMessageWrapper,
  StyledLoader,
  StyledHeading,
  GoogleMapWrapper,
  orderMapContainerDimensions,
}
